<script>
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  props: ['id'],

  data() {
    return {
      loading: false,
      loadingCustomers: false,
      loadingFarms: false,
      loadingModules: false,
      loadingPaddocks: false,
      valid: true,
      customers: [],
      farms: [],
      modules: [],
      paddocks: [],
      form: {},

      geolocation: {
        latitude: null,
        longitude: null,
      },

      types: [
        { text: 'Campo', value: 'CAMPO' },
        { text: 'Óptico', value: 'OPTICO' },
        { text: 'Radar', value: 'RADAR' },
      ],

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        code: [(v) => !!v || 'code é obrigatório'],
        farm: [(v) => !!v || 'Fazenda é obrigatório'],
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['authStatus', 'user']),
  },

  async created() {
    this.customers = (await this.fetchCustomers()).map((item) => ({
      value: item.id,
      text: item.name,
    }));

    if (this.id !== 'new') {
      const form = await this.fetch();
      this.form = cloneDeep(form);
      delete this.form.id;
      delete this.form.__typename;
      // const geolocation = this.form.geolocation.replace('(', '').replace(')', '').split(',')
      this.geolocation.latitude = this.form.geo_lat
      this.geolocation.longitude = this.form.geo_long
      await this.populateFarms()
      this.form.farm_id = form.farm_id
      await this.populateModules()
      if (form.ref_module_id) {
        this.form.module_id = form.ref_module_id
        delete this.form.ref_module_id
      } else {
        this.form.module_id = form.module_id
      }
      await this.populatePaddocks()
      this.form.paddock_id = form.paddock_id
    }
  },

  methods: {
    save() {
      const result = this.paddocks.filter(item =>{ return item.value === this.form.paddock_id})
      if (result.length > 0) {
        this.form.module_id = result[0].module
      }
      if (!this.$refs.form.validate()) return false;
      if (this.id === 'new') this.insert();
      else this.update();
    },

    getFormSave(formParam) {
      const form = cloneDeep(formParam);
      form.captured_at = dayjs.tz(form.captured_at, dayjs.tz.guess())
      if (this.geolocation && this.geolocation.latitude && this.geolocation.longitude) {
        form.geo_lat = this.geolocation.latitude
        form.geo_long = this.geolocation.longitude
      } else {
        form.geolocation = null
      }
      form.mn = parseFloat(form.mn)
      form.ms_percentage = parseFloat(form.ms_percentage)
      form.ms = parseFloat(form.ms)
      form.pb = parseFloat(form.pb)
      form.fdn = parseFloat(form.fdn)
      form.em = parseFloat(form.em)
      return form
    },

    async insert() {
      this.loading = true;
      try {
        const form = this.getFormSave(this.form);
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($satellites_data: satellites_data_insert_input!) {
              insert_satellites_data_one(object: $satellites_data) {
                id
              }
            }
          `,
          variables: {
            satellites_data: form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/medicoes');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async update() {
      this.loading = true;
      try {
        const form = this.getFormSave(this.form);
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $satellites_data: satellites_data_set_input!) {
              update_satellites_data_by_pk(pk_columns: { id: $id }, _set: $satellites_data) {
                id
              }
            }
          `,
          variables: {
            id: this.id,
            satellites_data: form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/medicoes');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query satellites_data($id: uuid!) {
              satellites_data: satellites_data_by_pk(id: $id) {
                id
                paddock {
                  id
                  name
                  code
                  ref_module_id
                  module{
                    id
                    name
                    code
                    farm{
                      id
                      name
                    }
                  }
                }
                customer_id
                area
                captured_at
                fdn
                info_lost
                measurement_code
                pb
                visible_area
                visible_em
                visible_ms
                em
                type
                geolocation
                grazing_type
                mn
                ms
                ms_percentage
                ms_type
                geo_lat
                geo_long
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        const data = result.data.satellites_data;
        data.farm_id = data.paddock.module.farm.id
        data.module_id = data.paddock.module.id
        data.paddock_id = data.paddock.id
        if (data.paddock.ref_module_id) {
          data.ref_module_id = data.paddock.ref_module_id
        }
        delete data.paddock
        if (data.captured_at) data.captured_at = dayjs(data.captured_at).format('YYYY-MM-DDThh:mm');
        return data;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchCustomers() {
      this.loadingCustomers = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query customers {
              customers(order_by: [{ name: asc }]) {
                id
                name
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        this.loadingCustomers = false
        return result.data.customers;
      } catch (e) {
        console.error(e);
      }
      this.loadingCustomers = false
    },


    async populateFarms() {
      this.form.farm_id = null
      this.form.module_id = null
      this.form.paddock_id = null
      this.farms = (await this.fetchFarms()).map((item) => ({
        value: item.id,
        text: item.name,
      }));
    },

    async fetchFarms() {
      this.loadingFarms = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms ($customeId: uuid!) {
              farms(
                where: {
                  customer_id: { _eq: $customeId }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
              }
            }
          `,
          variables: {
            customeId: this.form.customer_id
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingFarms = false
        return result.data.farms;
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
    },

    async populateModules() {
      this.form.module_id = null
      this.form.paddock_id = null
      this.modules = (await this.fetchModules()).map((item) => ({
        value: item.id,
        text: item.name,
      }));
    },

    async fetchModules() {
      this.loadingModules = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query modules ($farmId: uuid!) {
              modules(
                where: {
                  farm_id: { _eq: $farmId },
                  temporary: {_eq: false}
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
                desactivated
              }
            }
          `,
          variables: {
            farmId: this.form.farm_id
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingModules = false
        return result.data.modules.filter(element => !element.desactivated);
      } catch (e) {
        console.error(e);
      }
      this.loadingModules = false
    },

    async populatePaddocks() {
      this.form.paddock_id = null
      this.paddocks = (await this.fetchPaddocks()).map((item) => ({
        value: item.id,
        text: item.name,
        module: item.module? item.module.temporary? item.ref_module_id : item.module_id : null
      }));
    },

    async fetchPaddocks() {
      this.loadingPaddocks = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query paddocks ($moduleId: uuid!) {
              paddocks(
                where: {
                  _or: [
                    { ref_module_id: {_eq: $moduleId } }, 
                    { module_id: {_eq: $moduleId } }
                  ]
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
                module_id
                ref_module_id
                desactivated
                module {
                  temporary
                }
              }
            }
          `,
          variables: {
            moduleId: this.form.module_id
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingPaddocks = false
        return result.data.paddocks.filter(element => !element.desactivated);
      } catch (e) {
        console.error(e);
      }
      this.loadingPaddocks = false
    },

    calculateMS() {
      if (this.form.mn && this.form.ms_percentage) {
        const mn = parseFloat(this.form.mn)
        const msPercentage = parseFloat(this.form.ms_percentage)
        this.$set(this.form, 'ms', mn * msPercentage / 100)
      }
    },

    parseGeolocation() {
      this.form.geolocation = this.geolocation.latitude + ',' + this.geolocation.longitude
    },
    
    deleteFieldsOnChange() {
      this.form.grazing_type = null
      this.form.mn = null
      this.form.ms_percentage = null
      this.form.ms = null
      this.form.ms_type = null
      this.geolocation.latitude = null
      this.geolocation.longitude = null
      this.form.pb = null
      this.form.fdn = null
      this.form.em = null
      this.form.area = null
      this.form.info_lost = 0
      this.form.visible_area = null
      this.form.visible_ms = null
      this.form.visible_em = null
    },
  }
}
</script>

<template>
  <div>
    <h1 class="mb-5">Ajuste de medição</h1>

    <v-card class="pa-5">
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="form.customer_id"
                :items="customers"
                :rules="validations.customer"
                :loading="loadingCustomers"
                :disabled="id !== 'new' && user.role !== 'manager'"
                label="Cliente"
                outlined
                persistent-placeholder
                @input="populateFarms"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.farm_id"
                :items="farms"
                :rules="validations.farm"
                :disabled="!form.customer_id || user.role !== 'manager'"
                :loading="loadingFarms"
                label="Fazenda"
                outlined
                persistent-placeholder
                @input="populateModules"
              />
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                v-model="form.module_id"
                :items="modules"
                :rules="validations.modules"
                :disabled="!form.farm_id || user.role !== 'manager' "
                :loading="loadingModules"
                label="Módulo"
                outlined
                persistent-placeholder
                @input="populatePaddocks"
              />
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                v-model="form.paddock_id"
                :items="paddocks"
                :rules="validations.paddock"
                :disabled="!form.module_id || user.role !== 'manager'"
                :loading="loadingPaddocks"
                
                label="Piquete"
                outlined
                persistent-placeholder
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.captured_at"
                label="Data de captura"
                type="datetime-local"
                :disabled="user.role !== 'manager'"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                v-model="form.type"
                :items="types"
                label="Tipo de coleta"
                :disabled="user.role !== 'manager'"
                outlined
                persistent-placeholder
                @change="deleteFieldsOnChange()"
              />
            </v-col>

            <template v-if="form.type === 'CAMPO'">
              <v-col cols="12" md="2">
                <v-select
                  v-model="form.grazing_type"
                  :items="[{text: 'Pré pastejo', value: 'PRE'},{text: 'Pós pastejo', value: 'POS'}]"
                  label="Pré ou Pós Pastejo"
                  :disabled="user.role !== 'manager'"
                  outlined
                  persistent-placeholder
                ></v-select>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.mn"
                  label="Matéria Natural"
                  suffix="kg/ha"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @blur="calculateMS()"
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.ms_percentage"
                  label="Teor de Matéria Seca"
                  suffix="%"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @blur="calculateMS()"
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.ms"
                  label="Matéria Seca"
                  suffix="kg/piquete"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                  v-model="form.ms_type"
                  :items="[{text: 'Estimado', value: 'ESTIMADO'},{text: 'Medido', value: 'MEDIDO'}]"
                  label="Medição da MS"
                  :disabled="user.role !== 'manager'"
                  outlined
                  persistent-placeholder
                ></v-select>
              </v-col>
            </template>

            <template v-if="['OPTICO', 'RADAR'].includes(form.type)">
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.area"
                  label="Área"
                  suffix="ha"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.fdn"
                  label="Fibra em detergente neutro"
                  suffix="%"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.info_lost"
                  label="Informação perdida"
                  suffix="%"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <!-- <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.measurement_code"
                  label="Código de medição"
                  outlined
                  persistent-placeholder
                />
              </v-col> -->

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.pb"
                  label="Proteína bruta"
                  suffix="%"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.visible_area"
                  label="Área visível"
                  suffix="ha"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.visible_ms"
                  label="MS visível"
                  suffix="kg"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.visible_em"
                  label="EM visível"
                  suffix="kcal"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.em"
                  label="Energia metabolizável"
                  suffix="kcal/kg"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>
            </template>
          </v-row>

          <template v-if="form.type === 'CAMPO'">
            <v-divider />

            <h4 class="mb-4 mt-6">Coordenada da amostra (Graus)</h4>

            <v-row>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="geolocation.latitude"
                  label="Latitude"
                  :disabled="user.role !== 'manager'"
                  outlined
                  persistent-placeholder
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="geolocation.longitude"
                  label="Longitude"
                  :disabled="user.role !== 'manager'"
                  outlined
                  persistent-placeholder
                />
              </v-col>
            </v-row>

            <v-divider />

            <h4 class="mb-4 mt-6">Análises laboratoriais</h4>

            <v-row>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.pb"
                  label="Proteína bruta"
                  suffix="%"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.fdn"
                  label="Fibra em detergente neutro"
                  title="Fibra em detergente neutro"
                  suffix="%"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="form.em"
                  label="Energia metabolizável"
                  suffix="kcal/kg"
                  type="number"
                  :disabled="user.role !== 'manager'"
                  class="text-field-right text-field-suffix-light"
                  outlined
                  persistent-placeholder
                  @keydown.190.prevent
                  @keydown.110.prevent
                />
              </v-col>
            </v-row>
          </template>
          <div class="d-flex">
            <v-spacer />
            <v-btn v-if="user.role === 'manager'" color="primary" plain class="mr-2" to="/medicoes">Cancelar</v-btn>
            <v-btn v-if="user.role === 'manager'" color="success" :loading="loading" :disabled="loading" @click="save"> Salvar </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
